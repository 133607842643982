/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  useToast,
  chakra,
  Stack,
} from "@chakra-ui/react";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useClipboard } from "@chakra-ui/react";

import { callApiWithToken } from "../../utils/utils";
import { getOrgId } from "../../components/App/useToken";

const RefundModal = ({ isOpen, onClose, selectedRowData, refreshGrid }) => {

  const toast = useToast();
  const [isEUR, setIsEUR] = useState(false);
  const reasonOptions = [{label: "Reason1", value: "Reason1"}, {label: "Reason2", value: "Reason2"}, {label: "Reason3", value: "Reason3"}];

  useEffect(()=>{
   if(selectedRowData?.original?.fiat.partner === 'D24'){
    getConfig();
   } 
   if(selectedRowData?.original?.fiatTicker === 'EUR'){
    setIsEUR(true);
   } else{
    setIsEUR(false);
   }
  },[selectedRowData])

  const formik = useFormik({
    initialValues: {
      bankName: '',
      bankAccountNumber: '',
      bankAccountType: '',
      bankBranch: '',
      ifscNumber: '',
      swiftCode: '',
      bankBranchNumber: '',
      userName: ''
    },
    onSubmit: async (formValues) => {
        try {
            const response = await callApiWithToken("POST", "/api/refund/editAccountDetails", {
                refundAmount : selectedRowData?.original?.fiatRefund?.amount,
                bankName: formValues?.bankName,
                bankAccountNumber: formValues?.bankAccountNumber,
                bankAccountType: formValues?.bankAccountType,
                bankBranch: formValues?.bankBranch,
                ifscNumber: formValues?.ifscNumber,
                swiftCode: formValues?.swiftCode,
                bankBranchNumber: formValues?.bankBranchNumber,
                userName: formValues?.userName,
                currency: selectedRowData?.original?.fiatTicker,
                orderId: selectedRowData?.original?.orderId,
                userId: selectedRowData?.original?.userId,
                partner: selectedRowData?.original?.fiat?.partner
            });
            if (response?.message) {
                toast({
                    title: 'Success',
                    description: response?.message,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right'
                })
                onClose();
                refreshGrid();
            }
            if (response?.error) {
                toast({
                    title: 'Error!',
                    description: response?.error,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right'
                })
            }
            refreshGrid();
            onClose();
        } catch (err) {
            toast({
                title: 'Error!',
                description: response?.message || "Failed to fetch order details.",
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top-right'
            })
        }
        formik.setValues({
            bankName: '',
            bankAccountNumber: '',
            bankAccountType: '',
            bankBranch: '',
            ifscNumber: '',
            swiftCode: '',
            bankBranchNumber: '',
            userName: '',
        });
    },
  });

  return (
    <chakra.form display="flex" flexGrow={1} onSubmit={formik.handleSubmit}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center">Account Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack
              direction={"column"}
              justifyContent="space-between"
              flexGrow={1}
            >
              <FormControl
                isInvalid={formik.touched.bankName && formik.errors.bankName}
              >
                <FormLabel htmlFor="bankName">Refund Amount</FormLabel>
                <Input
                  id="refundAmount"
                  type="text"
                  placeholder="Enter refund amount"
                  value={selectedRowData?.original?.fiatRefund?.amount}
                  onChange={formik.handleChange}
                  disabled = {true}
                />
                <FormErrorMessage>{formik.errors.refundAmount}</FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={formik.touched.bankName && formik.errors.bankName}
              >
                <FormLabel htmlFor="bankName">Bank Name</FormLabel>
                <Input
                  id="bankName"
                  type="text"
                  placeholder="Enter the bank name"
                  value={formik.values.bankName}
                  onChange={formik.handleChange}
                />
                <FormErrorMessage>{formik.errors.bankName}</FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={
                  formik.touched.bankAccountNumber &&
                  formik.errors.bankAccountNumber
                }
              >
                <FormLabel htmlFor="bankAccountNumber">
                  Bank Account Number
                </FormLabel>
                <Input
                  id="bankAccountNumber"
                  type="text"
                  placeholder="Enter the bank account number"
                  value={formik.values.bankAccountNumber}
                  onChange={formik.handleChange}
                />
                <FormErrorMessage>
                  {formik.errors.bankAccountNumber}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={
                  formik.touched.bankAccountType &&
                  formik.errors.bankAccountType
                }
              >
                <FormLabel htmlFor="bankAccountType">
                  Bank Account Type
                </FormLabel>
                <Input
                  id="bankAccountType"
                  type="text"
                  placeholder="Enter the bank account type"
                  value={formik.values.bankAccountType}
                  onChange={formik.handleChange}
                />
                <FormErrorMessage>
                  {formik.errors.bankAccountType}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={
                  formik.touched.bankBranch && formik.errors.bankBranch
                }
              >
                <FormLabel htmlFor="bankBranch">Bank Branch</FormLabel>
                <Input
                  id="bankBranch"
                  type="text"
                  placeholder="Enter the bank branch"
                  value={formik.values.bankBranch}
                  onChange={formik.handleChange}
                />
                <FormErrorMessage>{formik.errors.bankBranch}</FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={
                  formik.touched.ifscNumber && formik.errors.ifscNumber
                }
              >
                <FormLabel htmlFor="ifscNumber">IFSC Number</FormLabel>
                <Input
                  id="ifscNumber"
                  type="text"
                  placeholder="Enter the IFSC number"
                  value={formik.values.ifscNumber}
                  onChange={formik.handleChange}
                />
                <FormErrorMessage>{formik.errors.ifscNumber}</FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={formik.touched.swiftCode && formik.errors.swiftCode}
              >
                <FormLabel htmlFor="swiftCode">SWIFT Code</FormLabel>
                <Input
                  id="swiftCode"
                  type="text"
                  placeholder="Enter the SWIFT code"
                  value={formik.values.swiftCode}
                  onChange={formik.handleChange}
                />
                <FormErrorMessage>{formik.errors.swiftCode}</FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={
                  formik.touched.bankBranchNumber &&
                  formik.errors.bankBranchNumber
                }
              >
                <FormLabel htmlFor="bankBranchNumber">
                  Bank Branch Number
                </FormLabel>
                <Input
                  id="bankBranchNumber"
                  type="text"
                  placeholder="Enter the bank branch number"
                  value={formik.values.bankBranchNumber}
                  onChange={formik.handleChange}
                />
                <FormErrorMessage>
                  {formik.errors.bankBranchNumber}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={formik.touched.userName && formik.errors.userName}>
                    <FormLabel htmlFor="userName">Bank Account Holder Name</FormLabel>
                    <Input
                      id="userName"
                      type="text"
                      placeholder="Enter the name of the user"
                      value={formik.values.userName}
                      onChange={formik.handleChange}
                    />
                    <FormErrorMessage>{formik.errors.userName}</FormErrorMessage>
                  </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter justifyContent="center">
            {/* <Button colorScheme="blue" mr={3} type="submit" isLoading={formik.isSubmitting}> */}
            <Button colorScheme="blue" mr={3} onClick={formik.handleSubmit} isLoading={formik.isSubmitting}>
              Initiate
            </Button>
            <Button
              variant="ghost"
              onClick={onClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </chakra.form>
  );
};

export default RefundModal;