import React, { useState, useContext, useRef, useEffect } from "react";
import Loader from "react-js-loader";
import { startCase } from "lodash";
import { Box, Button, useDisclosure, Text, useToast, Modal, ModalOverlay, ModalHeader, ModalContent, ModalCloseButton, ModalBody, ModalFooter } from "@chakra-ui/react";
import TransfiGrid from "../../utils/TransfiGrid";
import { callApiWithToken } from "../../utils/utils";

export default function ApproveWallet() {
  const [loading, setLoading] = useState(false);
  const [selectedDeclineRow, setSelectedDeclineRow] = useState({});
  const [selectedApproveRow, setSelectedApproveRow] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isDeclineOpen, onOpen: onDeclineOpen, onClose: onDeclineClose } = useDisclosure();

  const gridRef = useRef();
  const toast = useToast();

  useEffect(() => {
    refreshGrid;
  }, []);

  const refreshGrid = () => {
    gridRef.current.refreshGrid();
  };


  const handleDeclineClick = (rowData) => {
    setSelectedDeclineRow(rowData?.original);
    onDeclineOpen();
  };

  const handleDeclineOnCLose = () => {
    setSelectedDeclineRow(null);
    onDeclineClose();
  };

  const handleApproveClick = (rowData) => {
    setSelectedApproveRow(rowData?.original);
    onOpen();
  };

  const handleApproveOnCLose = () => {
    setSelectedApproveRow(null);
    onClose();
  };

  const approveWallet = async () => {
    setLoading(true);
    try {
      const updatedData = {
        id: selectedApproveRow?._id,
        status: "approved",
      };
      const response = await callApiWithToken('POST', '/api/treasury/approveWallet', updatedData);
      if (response?.message) {
        toast({
          title: 'Success',
          description: response?.message,
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right'
        })
      }
    }
    catch (error) {
      console.log(error);
      toast({
        title: 'Error!',
        description: "Failed to Update the live rate",
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right'
      })
    }
    setLoading(false);
    handleApproveOnCLose();
  };


  const declineWallet = async () => {
    setLoading(true);
    try {
      const updatedData = {
        id: selectedDeclineRow?._id,
        status: "declined",
      };
      const response = await callApiWithToken('POST', '/api/treasury/approveWallet', updatedData);
      if (response?.message) {
        toast({
          title: 'Success',
          description: response?.message,
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right'
        })
      }
    }
    catch (error) {
      toast({
        title: 'Error!',
        description: "Failed to Update the live rate",
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right'
      })
    }
    setLoading(false);
    handleDeclineOnCLose();
  };

  const columns = [
    {
      Header: "Wallet Name",
      width: "150",
      accessor: "walletName",
    },
    {
      Header: "Address",
      width: "150",
      accessor: "walletAddress",
    },
    {
      Header: "Type",
      width: "150",
      accessor: "contactType",
      Cell: ({ value }) => startCase(value) || "-",
    },
    {
      Header: "Token",
      width: "150",
      accessor: "token",
    },
    {
      Header: "Network",
      width: "150",
      accessor: "network",
    },
    {
      Header: "Action",
      accessor: "",
      Width: "150",
      Cell: ({ row }) => {
        return (
          <>
            <Button
              style={{ marginRight: "15px" }}
              size="sm"
              colorScheme="red"
              onClick={() => {
                handleDeclineClick(row)
              }}
            >
              Decline
            </Button>
            <Button
              size="sm"
              colorScheme="blue"
              onClick={() => {
                handleApproveClick(row);
              }}
            >
              Approve
            </Button>
          </>

        );
      },
    }
  ];


  return !loading ? (
    <Box style={{ background: "white" }} heading="Live LiveRates">
      <TransfiGrid
        columns={columns}
        dataUrl={`api/treasury/walletList`}
        filters={{}}
      ></TransfiGrid>
      <Modal isOpen={isOpen} onClose={handleApproveOnCLose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Approve Wallet</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Are you sure you want to approve the wallet?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={handleApproveOnCLose}>
              Close
            </Button>
            <Button
              colorScheme="blue"
              onClick={approveWallet} isLoading={loading}
            >
              Approve
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isDeclineOpen} onClose={handleDeclineOnCLose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Decline Wallet</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Are you sure you want to decline the wallet?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={handleDeclineOnCLose}>
              Close
            </Button>
            <Button
              colorScheme="red"
              onClick={declineWallet} isLoading={loading}
            >
              Decline
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  ) : (
    <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={"Loading..."} color={"cyan.400"} size={100} />
  );
}