import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Modal,
  ModalCloseButton,
  FormControl,
  Input,
  HStack,
  useDisclosure,
  ModalFooter,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  Button,
  FormLabel,
  Select,
  Spinner,
} from "@chakra-ui/react";
import {  useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import { callApiWithToken } from "../../../utils/utils";
import { AddIcon } from "@chakra-ui/icons";
import { BiRefresh } from "react-icons/bi";
import { notify } from "react-notify-toast";
import { AgGridReact } from "ag-grid-react"; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

export default function PaymentGateway() {
  const ActionButtons = ({ data }) => (
    <HStack>
      <Button onClick={() => handleEditClick(data)} size="sm">
        Edit
      </Button>
      <Button onClick={() => handleDeleteClick(data)} size="sm" colorScheme="red">
        Delete
      </Button>
    </HStack>
  );
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isDeleteModalOpen, onOpen: openDeleteModal, onClose: closeDeleteModal } = useDisclosure();
  const { isOpen: isOpenAddModal, onOpen: onOpenAddModal, onClose: onCloseAddModal } = useDisclosure();
  const [colDefs, setColDefs] = useState([
    { headerName: "Payment Gateway", field: "name", filter: true },
    { headerName: "Reference Name", field: "referenceName", filter: true },
    { headerName: "Actions", cellRenderer: ActionButtons },
  ]);

  const refreshGrid = async () => {
    try {
      setIsRefreshing(true);
      const response = await callApiWithToken("GET", "/api/admin/listPaymentGateways");
      setRowData(response.data.paymentGateways);
      console.log("refresh complete");
    } catch (error) {
      console.error("Error refreshing payment methods:", error);
    }
    setIsRefreshing(false);
  };

  const closeModal = () => {
    setSelectedRowData(null);
    onClose();
  };
  useEffect(() => {
    try {
      const fetchData = async () => {
        const response = await callApiWithToken("GET", "/api/admin/listPaymentGateways");
        console.log(response.data);
        setRowData(response.data.paymentGateways);
      };
      fetchData();
    } catch (error) {
      console.log("Error occurred while fetching data: ", error);
    }
    setIsRefreshing(false);
  }, []);

  const handleEditClick = (rowData) => {
    setSelectedRowData(rowData);
    console.log(selectedRowData);
    onOpen();
  };
  const handleDeleteClick = (rowData) => {
    setSelectedRowData(rowData);
    openDeleteModal();
  };
  const handleConfirmDelete = async (rowData) => {
    setLoading(true);
    try {
      console.log(selectedRowData, "test");
      // console.log(rowData)
      const data = { _id: selectedRowData?._id };
      const response = await callApiWithToken("POST", "/api/admin/deletePaymentGateway", data);
      if (response.status) {
        notify.show("Payment Gateway Deleted Successfully", "custom", 5000, { background: "#4BB543", text: "white" });
      } else {
        notify.show("Error in Deleting Payment Gateway", "custom", 5000, { background: "#cc0000", text: "white" });
      }
    } catch (error) {
      console.log("Error while deleting the method: ", error);
    }
    setLoading(false);
    closeDeleteModal();
    refreshGrid();
  };
  const saveChanges = async () => {
    setLoading(true);
    try {
      const updatedData = {
        _id: selectedRowData?._id,
        name: selectedRowData?.name,
        referenceName: selectedRowData?.referenceName,
      };

      const response = await callApiWithToken("POST", "/api/admin/updatePaymentGateway", updatedData);
      setSelectedRowData(selectedRowData);
      const updatedRowData = rowData.map((row) => {
        if (row._id === selectedRowData._id) {
          return { ...row, ...selectedRowData };
        }
        return row;
      });
      setRowData(updatedRowData);
      if (response.status) {
        notify.show("Payment Gateway Updated Successfully", "custom", 5000, { background: "#4BB543", text: "white" });
      } else {
        notify.show("Error in Updating Payment Gateway", "custom", 5000, { background: "#cc0000", text: "white" });
      }
    } catch (error) {
      console.log("Error caught while saving gateway changes :", error);
    }
    console.log("changes made");
    setLoading(false);
    closeModal();
  };
  const formikAddModal = useFormik({
    initialValues: {
      referenceName: "",
      name: "",
    },
    validationSchema: Yup.object().shape({
      referenceName: Yup.string().required("Reference Name is required"),
      name: Yup.string().required("Name is required"),
    }),
    onSubmit: async (formValues) => {
      try {
        const response = await callApiWithToken("POST", "/api/admin/addPaymentGateway", { 
          name: formValues?.name,
          referenceName: formValues?.referenceName,
          weightage: formValues?.weightage,
         });
        if (response.status) {
          notify.show("Payment Gateway Added Successfully", "custom", 5000, { background: "#4BB543", text: "white" });
        } else {
          notify.show("Error in Saving New Payment Gateway", "custom", 5000, { background: "#cc0000", text: "white" });
        }
        onCloseAddModal();
        refreshGrid();
      } catch (error) {
        console.log("error caught while adding gateway", error);
      }
    },
  });

  return (
    <Box heading="Payment Method">
      <div style={{ marginTop: "2%", marginRight: "50%" }}>
        <div style={{ float: "right" }}>
          <Button leftIcon={<BiRefresh style={{ fontSize: "20px" }} />} onClick={() => refreshGrid()} variant={"outline"} colorScheme="blue" style={{ margin: "0 4px" }} size="sm">
            Refresh
          </Button>
          <Button variant={"outline"} colorScheme="blue" onClick={onOpenAddModal} style={{ margin: "0 4px" }} leftIcon={<AddIcon style={{ fontSize: "16px" }} />} size="sm">
            Add Payment Gateway
          </Button>
        </div>
        <br />
      </div>
      {isRefreshing ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 700, width: "100%", marginTop: "20px" }}>
          <Spinner size="xl" />
        </div>
      ) : (
        <div className="ag-theme-quartz" style={{ height: 700, width: "40%", marginTop: "20px", marginLeft: "5%" }}>
          <AgGridReact rowData={rowData} columnDefs={colDefs} pagination={true} paginationPageSize={500} paginationPageSizeSelector={[50, 100, 200, 500]} />
        </div>
      )}

      <Modal isOpen={isOpen} onClose={closeModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Payment Gateway</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Payment Gateway</FormLabel>
              <Input value={selectedRowData?.name} onChange={(e) => setSelectedRowData({ ...selectedRowData, name: e.target.value })} />
            </FormControl>
            <FormControl>
              <FormLabel>Reference Name</FormLabel>
              <Input value={selectedRowData?.referenceName} onChange={(e) => setSelectedRowData({ ...selectedRowData, referenceName: e.target.value })} />
            </FormControl>
            <FormControl >
                <FormLabel>Weightage</FormLabel>
                <Select >
                  <option value={selectedRowData?.weightage}>{selectedRowData?.weightage}</option>
                  {[1,2,3,4,5,6,7,8,9,10].map((method) => (
                    <option value={method}>{method}</option>
                  ))}
                </Select>
              </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={saveChanges} isLoading={loading}>
              Save
            </Button>
            <Button onClick={closeModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isDeleteModalOpen} onClose={closeDeleteModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Confirmation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to delete this payment method?</ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleConfirmDelete} isLoading={loading}>
              Delete
            </Button>
            <Button onClick={closeDeleteModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpenAddModal} onClose={onCloseAddModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Payment Gateway</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormikProvider value={formikAddModal}>
              <FormControl isRequired isInvalid={formikAddModal.touched.name && formikAddModal.errors.name}>
                <FormLabel> Payment Gateway </FormLabel>

                <Input id="name" type="text" color={"black"} onChange={formikAddModal.handleChange} />
              </FormControl>
              <FormControl isRequired isInvalid={formikAddModal.touched.referenceName && formikAddModal.errors.referenceName}>
                <FormLabel> Reference Name </FormLabel>

                <Input id="referenceName" type="text" color={"black"} onChange={formikAddModal.handleChange} />
              </FormControl>
              <FormControl isRequired isInvalid={formikAddModal.touched.weightage && formikAddModal.errors.weightage}>
                <FormLabel>Weightage</FormLabel>
                <Select id="weightage" onChange={formikAddModal.handleChange}>
                  <option value={""}></option>
                  {[1,2,3,4,5,6,7,8,9,10].map((method) => (
                    <option value={method}>{method}</option>
                  ))}
                </Select>
              </FormControl>
            </FormikProvider>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" isLoading={loading} onClick={formikAddModal.handleSubmit} colorScheme="blue" mr={3}>
              Submit
            </Button>
            <Button onClick={onCloseAddModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
