import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { startCase } from "lodash";
import { Box, Button, useDisclosure, Badge, Text } from "@chakra-ui/react";
import TransfiGrid from "../../utils/TransfiGrid";
import { ReactComponent as MoneyMovement } from '../../icons/moneyMovement.svg';

export default function Treasury() {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const columns = [
        {
            Header: "Order Id",
            accessor: "orderId",
            width: 150,
            Cell: ({ value }) => {
                return <Link style={{ color: "#4ACDED", fontWeight: "bold" }} to={`/treasury-order/${value}`}>{value}</Link>
            }
        },
        {
            Header: "Status",
            accessor: "status",
            width: 150,
            Cell: ({ value }) => {
                const color = {
                    "completed": "green",
                    "initiated": "yellow",
                    "failed": "red"
                }
                return <Badge colorScheme={color[value]}> {startCase(value)} </Badge>;
            },
        },
        {
            Header: "Total Cost",
            accessor: "totalCost",
            width: 150,
            Cell: ({ value }) => {
                return value || "0"
            }
        },
        {
            Header: "Transfer Currency",
            accessor: "fromCcy",
            width: 150,
            Cell: ({ value }) => {
                return value || "NA"
            }
        },
        {
            Header: "Amount Sent",
            accessor: "fromAmount",
            width: 150,
            Cell: ({ value }) => {
                return value || "NA"
            }
        },
        {
            Header: "Output Currency",
            accessor: "toCcy",
            width: 150,
            Cell: ({ value }) => {
                return value || "NA"
            }
        },
        {
            Header: "Amount Received",
            accessor: "toAmount",
            width: 150,
            Cell: ({ value }) => {
                return value || "NA"
            }
        }
    ];

    return !loading ? (
        <Box style={{ background: "white" }}>
            <div style={{ float: "right", marginTop: "2%" }}>
                <Button
                    leftIcon={<MoneyMovement />}
                    colorScheme="blue"
                    style={{ margin: "0 8px" }}
                    size="sm"
                    onClick={() => {
                        navigate("/move-balance");
                    }}
                    // isDisabled={!orgRoles.includes("transfi_admin") ? true : false}
                >
                    Move Balance
                </Button>
                {/* <Button
                    leftIcon={<DownloadIcon />}
                   colorScheme='blue' variant='outline'
                    style={{ margin: "0 8px" }}
                    size="sm"
                    // onClick={onOpenSettleModal}
                    // isDisabled={!orgRoles.includes("transfi_admin") ? true : false}
                >
                    Download Report
                </Button>
                <Button
                    leftIcon={<FiFilter style={{ fontSize: "20px" }} />}
                    // onClick={onOpen}
                    variant={"outline"}
                    colorScheme="blue"
                    style={{ margin: "0 8px" }}
                    size="sm"
                >
                    Filter
                </Button> */}
            </div>
            <TransfiGrid
                columns={columns}
                dataUrl={"/api/treasury/list"}
                filters={{}}
            ></TransfiGrid>
        </Box>
    ) : (
        <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={"Loading..."} color={"cyan.400"} size={100} />
    );
}