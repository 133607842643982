import React, { Suspense, lazy, useState } from "react";

//Custom Components
const Login = lazy(() => import("./Login"));
import MFASetup from "./MFASetup";
import VerifyMFA from "./VerifyMFA";
import Loader from "../../../utils/Loader";

const LoginFlow = (props) => {
    const { setToken } = props || {};
    const [userEmail, setUserEmail] = useState(false);
    const [mfaStatus, setMfaStatus] = useState(false);

    return (
        <>
            <Suspense fallback={<Loader />}>
                {!userEmail && <Login setMfaStatus={setMfaStatus} setUserEmail={setUserEmail} />}
            </Suspense>
            {userEmail && !mfaStatus && <MFASetup email={userEmail} setMfaStatus={setMfaStatus} />}
            {userEmail && mfaStatus && <VerifyMFA email={userEmail} redirectLogin={true} setToken={setToken} />}
        </>
    );
};

export default LoginFlow;
