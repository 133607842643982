import { Button, FormControl, FormErrorMessage, FormLabel, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Text, useToast } from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import React, { useEffect, useState } from 'react';
import { isUndefined } from 'lodash';

import { callApiWithTokenV2 } from '../../../utils/utils';

const CollectionsSettlementModal = ({ isOpen, onClose, data, setSelect, handleReset }) => {

  const totalAmount = data.reduce((acc, obj) => acc + obj.netAmount, 0).toFixed(3);
  const totalAmountInSubAccount = data.reduce((acc, obj) => acc + obj.amountReceivedSubAccountFiat1, 0).toFixed(3);
  const totalProcessingFeesAmount = data.reduce((acc, obj) => acc + obj.processingFeesAmountFiat1, 0).toFixed(3);
  const { createdAt: orderDate = "", depositCurrency = "", fiat = {}, orgId = "" } = data[0] || {};
  const markupFee = fiat?.markupFee || 0;
  const orderIds = data.map(obj => obj.orderId);
  const [exchangeRateXe, setExchangeRateXe] = useState(0);
  const [exchangeRateTransFi, setExchangeRateTransFi] = useState(0);
  const [cryptoToBeDelivered, setCryptoToBeDelivered] = useState(0);
  const [cryptoTicker, setCryptoTicker] = useState('USDT');
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const getExchangeRate = async (depositCurrency) => {
    const payload = {
      totalAmount,
      markupFee,
      depositCurrency,
      withdrawCurrency: 'USD',
    }
    const { data } = await callApiWithTokenV2.post("/api/settlement/tfpaySettlement/data", payload) || {};
    const { transfiExchangeRate, exchangeRatePerXe, cryptoToBeDelivered, cryptoTicker } = data?.data || {}
    console.log(transfiExchangeRate, exchangeRatePerXe, cryptoToBeDelivered, data);
    setExchangeRateXe(exchangeRatePerXe);
    setExchangeRateTransFi(transfiExchangeRate);
    setCryptoToBeDelivered(cryptoToBeDelivered);
    setCryptoTicker(cryptoTicker);
  }

  useEffect(() => {
    if (depositCurrency) {
      getExchangeRate(depositCurrency);
    }
  }, [isOpen, depositCurrency])

  const validationSchema = yup.object({
    cryptoPaymentPartner: yup.string("Enter crypto payment partner").required('Crypto payment partner is required'),
    partnerOrderId: yup.string("Enter crypto partner order ID").required('Crypto partner order ID is required'),
    txHash: yup.string("Enter transaction hash").required('Transaction hash is required')
  });
  const formik = useFormik({
    initialValues: {
      cryptoPaymentPartner: '',
      partnerOrderId: '',
      txHash: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const payload = {
          totalAmount: Number(totalAmount),
          exchangeRate: Number(exchangeRateXe),
          exchangeRateWithMarkup: Number(exchangeRateTransFi),
          cryptoAmount: cryptoToBeDelivered,
          cryptoPaymentPartner: values.cryptoPaymentPartner,
          partnerOrderId: values.partnerOrderId,
          cryptoTicker: cryptoTicker,
          txHash: values.txHash,
          type: 'collections',
          orderIds: orderIds,
          orderDate: orderDate,
          fiatTicker: depositCurrency.toUpperCase(),
          totalAmountInSubAccount,
          totalProcessingFeesAmount,
          orgId
        };
        const { data } = await callApiWithTokenV2.post("/api/settlement/tfpaySettlement/collections", payload) || {};
        const { success = false } = data;
        if (success) {
          toast({
            title: "Settlement created successfully",
            status: 'success',
            duration: 5000,
            isClosable: true
          });
          setLoading(false);
          handleReset()
          handleCloseModal()
        }
      }
      catch (e) {
        toast({
          title: e?.message,
          status: 'error',
          duration: 5000,
          isClosable: true
        })
        setLoading(false);
        handleCloseModal()
      }
    },
  });

  const handleCloseModal = () => {
    setLoading(false);
    setSelect(false);
    formik.resetForm();
    onClose();
  }
  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal} motionPreset='slideInBottom' size={'3xl'} >
      <ModalOverlay />
      <ModalContent width={'7xl'} bg={'#FAFAFA'} p={5}>
        <ModalHeader >
          <Text style={{ color: "#1A4FD6", fontFamily: "arial" }}>Create Settlement</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SimpleGrid columns={2} spacing={5} mb={5} justifyItems={'center'}>
            <FormControl isRequired isInvalid={formik?.touched?.totalAmount && formik?.errors?.totalAmount} >
              <FormLabel>Total Amount {`(${depositCurrency})`}</FormLabel>
              <Input
                id='totalAmount'
                name='totalAmount'
                width={'full'}
                value={totalAmount}
                onChange={formik.handleChange}
                readOnly
              />
              <FormErrorMessage>{formik?.errors?.totalAmount}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={formik?.touched?.totalAmount && formik?.errors?.totalAmount} >
              <FormLabel>FX Markup</FormLabel>
              <Input
                id='fxMarkup'
                name='fxMarkup'
                width={'full'}
                value={`${markupFee * 100} %`}
                onChange={formik.handleChange}
                readOnly
              />
              <FormErrorMessage>{formik?.errors?.totalAmount}</FormErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={formik?.touched?.exchangeRate && formik?.errors?.exchangeRate}>
              <FormLabel>Exchange Rate As per XE</FormLabel>
              <Input
                width={'full'}
                id='exchangeRateXe'
                name='exchangeRateXe'
                value={exchangeRateXe}
              />
              <FormErrorMessage>{formik?.errors?.exchangeRate}</FormErrorMessage>
            </FormControl>


            <FormControl isRequired isInvalid={formik?.touched?.exchangeRate && formik?.errors?.exchangeRate}>
              <FormLabel>TransFi Exchange Rate</FormLabel>
              <Input
                width={'full'}
                id='exchangeRateTransfi'
                name='exchangeRateTransFi'
                value={exchangeRateTransFi}
              />
              <FormErrorMessage>{formik?.errors?.exchangeRate}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={formik?.touched?.cryptoAmount && formik?.errors?.cryptoAmount}>
              <FormLabel>Crypto Amount</FormLabel>
              <Input
                width={'full'}
                id='cryptoAmount'
                name='cryptoAmount'
                value={cryptoToBeDelivered || 0}
                readOnly
              />
              <FormErrorMessage>{formik.errors.cryptoAmount}</FormErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={formik?.touched?.cryptoPaymentPartner && formik?.errors?.cryptoPaymentPartner}>
              <FormLabel>Crypto Payment Partner</FormLabel>
              <Input
                width={'full'}
                id='cryptoPaymentPartner'
                name='cryptoPaymentPartner'
                onChange={formik.handleChange} />
              <FormErrorMessage>{formik?.errors?.cryptoPaymentPartner}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={formik?.touched?.partnerOrderId && formik?.errors?.partnerOrderId}>
              <FormLabel>Crypto Partner Order ID</FormLabel>
              <Input
                width={'full'}
                id='partnerOrderId'
                name='partnerOrderId'
                onChange={formik.handleChange} />
              <FormErrorMessage>{formik?.errors?.partnerOrderId}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={formik?.touched?.cryptoTicker && formik?.errors?.cryptoTicker}>
              <FormLabel>Crypto Ticker</FormLabel>
              <Input
                width={'full'}
                id='cryptoTicker'
                name='cryptoTicker'
                value={cryptoTicker} />
              <FormErrorMessage>{formik?.errors?.cryptoTicker}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={formik?.touched?.txHash && formik?.errors?.txHash}>
              <FormLabel>Transaction Hash</FormLabel>
              <Input
                width={'full'}
                id='txHash'
                name='txHash'
                onChange={formik.handleChange} />
              <FormErrorMessage>{formik?.errors?.txHash}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>

          <HStack mt={2} justify={'center'}>
            <Button
              w={"150px"}
              bg="#ffffff"
              color="#525C76"
              border={"1px"}
              borderColor={"#525C76"}
              variant={"outline"}
              onClick={handleCloseModal}
              _hover={{ bg: '#ffffff' }}
            >
              Cancel
            </Button>
            <Button
              isDisabled={!formik.values.cryptoPaymentPartner || !formik.values.partnerOrderId || !formik.values.txHash}
              isLoading={loading}
              bg="#1A4FD6"
              w={"150px"}
              color={"#ffffff"}
              _hover={{ bg: "#232d95" }}
              onClick={formik.handleSubmit}
            >
              Create
            </Button>
          </HStack>

        </ModalBody>

      </ModalContent>
    </Modal>
  );
};

export default CollectionsSettlementModal;