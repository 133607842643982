import { Box, Button, Center, Flex, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useDisclosure, useToast } from '@chakra-ui/react';
import { startCase, sumBy } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';

import SettlementConfirmModal from './SettlementConfirmModal';
import { callApiWithTokenV2 } from '../../utils/utils';
import { DownloadIcon } from '@chakra-ui/icons';

const CreatePayinSettlement = () => {
    const location = useLocation();
    const data = location.state;
    const { startDate = '', endDate = '', orgId = '', finalResult = [], orgName = '' } = data || {};
    const totalAmountInUsdt = sumBy(finalResult, 'usdtToBeSent');
    const USformatter = new Intl.NumberFormat('en-US');
    const [loading, setLoading] = useState(false);

    const { isOpen, onOpen, onClose } = useDisclosure();

    const navigate = useNavigate();

    const toast = useToast();

    const handleDownload = () => {
        const tableData = finalResult.map((data) => ({
            Currency: data?._id,
            "Total Collections": USformatter.format(data?.totalCollections),
            "Total Payouts": USformatter.format(data?.totalPayouts),
            "Net Amount": USformatter.format(data?.netAmount),
            "Conversion Rate": USformatter.format(data?.exchangeRate),
            "Fx Markup": `${Number(data?.markupFees || 0) * 100} %`,
            "TransFi Conversion Rate": USformatter.format(data?.exRateAfterFxMarkup),
            "USDT to be Sent": USformatter.format(data?.usdtToBeSent),
        }));

        // Add the total row
        tableData.push({
            Currency: 'Total',
            "USDT to be Sent": USformatter.format(totalAmountInUsdt),
        });

        const fileName = `Settlement_${startCase(orgName)}_${moment(startDate).format('YYYY-MM-DD')}_to_${moment(endDate).format('YYYY-MM-DD')}`
        const ws = XLSX.utils.json_to_sheet(tableData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Table Data");

        // Generate the excel file and download
        XLSX.writeFile(wb, `${fileName}.xlsx`);
    };


    const handleCreateSettlement = async () => {
        try {
            setLoading(true);
            const payload = { details: finalResult, totalAmountInUsdt, orgId, startDate, endDate }
            const { data: response } = await callApiWithTokenV2.post(`/api/settlement/tfpaySettlement`, { ...payload })
            if (response) {
                setLoading(false);
                onClose();
                toast({
                    title: "Success",
                    description: "Settlement created successfully",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                navigate('/payin-orders')
            }
            setLoading(false)
        } catch (err) {
            console.log(err);
        }
    }

    const handleConfirm = () => {
        onOpen();
    }

    return (
        <Box w="100%" p={4}>
            <Flex justify={'space-between'}>
                <Text fontSize={'xl'}>Showing summary of orders from <Text as={'span'} color={"#1A4FD6"} fontWeight={'semibold'}>{moment(startDate).format('YYYY-MM-DD')} </Text>to  <Text as={'span'} color={"#1A4FD6"} fontWeight={'semibold'}>{moment(endDate).format('YYYY-MM-DD')} </Text></Text>
                <Button
                    leftIcon={<DownloadIcon />}
                    onClick={handleDownload}
                    isLoading={loading}
                    variant={"outline"}
                    colorScheme="blue"
                    style={{ margin: "0 4px" }}
                    size="sm"
                >
                    Download XLSX
                </Button>
            </Flex>
            <TableContainer mt={5}>
                <Table variant='striped' colorScheme='gray'>
                    <Thead>
                        <Tr>
                            <Th>Currency</Th>
                            <Th>Total Collections</Th>
                            <Th>Total Payouts</Th>
                            <Th>Net Amount</Th>
                            <Th>Conversion Rate</Th>
                            <Th>Fx Markup</Th>
                            <Th>TransFi Conversion rate</Th>
                            <Th>USDT to be sent</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {finalResult?.map((data, i) => <Tr key={i}>
                            <Td>{data?._id}</Td>
                            <Td>{USformatter.format(data?.totalCollections)}</Td>
                            <Td>{USformatter.format(data?.totalPayouts)}</Td>
                            <Td>{USformatter.format(data?.netAmount)}</Td>
                            <Td>{USformatter.format(data?.exchangeRate)}</Td>
                            <Td>{Number(data?.markupFees || 0) * 100} %</Td>
                            <Td>{USformatter.format(data?.exRateAfterFxMarkup)}</Td>
                            <Td>{USformatter.format(data?.usdtToBeSent)}</Td>
                        </Tr>)}
                        <Tr>
                            <Td>{data?._id}</Td>
                            <Td></Td>
                            <Td></Td>
                            <Td></Td>
                            <Td></Td>
                            <Td></Td>
                            <Td>Total</Td>
                            <Td>{USformatter.format(totalAmountInUsdt)}</Td>
                        </Tr>

                    </Tbody>
                </Table>
            </TableContainer>
            <Center>
                <Button
                    bg="#1A4FD6"
                    w={"150px"}
                    color={"#ffffff"}
                    _hover={{ bg: "#232d95" }}
                    mt={5}
                    onClick={handleConfirm}>
                    Confirm
                </Button>
            </Center>
            <SettlementConfirmModal isOpen={isOpen} onClose={onClose} settlementAmount={totalAmountInUsdt} settlementCurrency={'USDT'} handleCreateSettlement={handleCreateSettlement} loading={loading} setLoading={setLoading} />
        </Box>
    );
};

export default CreatePayinSettlement;