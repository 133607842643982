import React, { useState, useContext, useRef, useEffect } from "react";
import Loader from "react-js-loader";
import { useParams } from 'react-router-dom';
import { round, startCase } from "lodash";
import { Box, Button, useDisclosure, Badge, Text } from "@chakra-ui/react";
import TransfiGrid from "../../utils/TransfiGrid";
import TimezoneFormatter from "../../utils/TimezoneFormatter";
export default function TreasuryTransactions() {
    const { orderId } = useParams(); // Capture the URL parameter
    const [loading, setLoading] = useState(false);
    const columns = [
        {
            Header: "Transaction Movement",
            width: "150",
            Cell: ({ row }) => {
                const fromPartner = row?.original?.from;
                const toPartner = row?.original?.to;
                return `${startCase(fromPartner)} to ${startCase(toPartner)}` || "NA"
            }
        },
        {
            Header: "Transaction Id",
            accessor: "txId",
            width: "150",
            Cell: ({ value }) => {
                return value || "NA"
            }
        },
        {
            Header: "Status",
            width: "150",
            accessor: "status",
            Cell: ({ value }) => {
                const color = {
                    "completed": "green",
                    "txn_initiated": "yellow",
                    "txn_failed": "red",
                    "created": "blue"
                }
                return <Badge colorScheme={color[value]}> {startCase(value)} </Badge>;
            },
        },
        {
            Header: "Date Of Recipient",
            width: "200",
            Cell: ({ row }) => {
                return <TimezoneFormatter date={row?.original?.timestamps?.createdAt} format={"MM/DD/YYYY"} />
            },
        },
        {
            Header: "Time Of Recipient",
            width: "200",
            Cell: ({ row }) => {
                return <TimezoneFormatter date={row?.original?.timestamps?.createdAt} format={"LT"} />
            },
        },
        {
            Header: "Date Of Transfer",
            width: "200",
            Cell: ({ row }) => {
                return <TimezoneFormatter date={row?.original?.timestamps?.completedAt} format={"MM/DD/YYYY"} />
            },
        },
        {
            Header: "Time Of Transfer",
            width: "200",
            Cell: ({ row }) => {
                return <TimezoneFormatter date={row?.original?.timestamps?.completedAt} format={"LT"} />
            },
        },
        {
            Header: "Conversion Rate",
            width: "200",
            Cell: ({ row }) => {
                const conversionRate = Number(1 / row?.original?.details?.conversionRate);
                return round(conversionRate, 2) || "-"
            }
        },
        {
            Header: "Xe Rate",
            width: "200",
            Cell: ({ row }) => {
                const conversionRate = Number(row?.original?.details?.xeRate);
                return conversionRate || "-"
            }
        },
        {
            Header: "Amount In USD",
            width: "200",
            Cell: ({ row }) => {
                const conversionRate = row?.original?.details?.xeRate;
                const amount = row?.original?.amount;
                return conversionRate ? amount / conversionRate : "-"
            }
        },
        {
            Header: "Treasury Cost (%)",
            width: "150",
            Cell: ({ row }) => {
                const conversionRate = Number(1 / row?.original?.details?.conversionRate, 0);
                const xeRate = Number(row?.original?.details?.xeRate);
                const cost = round((conversionRate - xeRate) / xeRate || 0, 2);
                return xeRate ? cost : "-"
            }
        },
        {
            Header: "Treasury Cost (USD)",
            width: "150",
            Cell: ({ row }) => {
                const conversionRate = Number(1 / row?.original?.details?.conversionRate, 0);
                const xeRate = Number(row?.original?.details?.xeRate);
                const cost = round((conversionRate - xeRate) / xeRate || 0, 2);
                const amount = row?.original?.amount;
                const amountUsd = amount / xeRate;
                return xeRate ? round(cost * amountUsd, 2) : "-"
            }
        },
        {
            Header: "Fees charged for conversion",
            width: "150",
            Cell: ({ row }) => {
                const feeForConversion = row?.original?.details?.feeForConversion;
                return feeForConversion || "-"
            }
        },
        {
            Header: "Fees charged for movement",
            width: "150",
            Cell: ({ row }) => {
                const { stepType } = row?.original;
                let feeForMovement = row?.original?.details?.feeForMovement;
                if (stepType === "exchange") {
                    feeForMovement = row?.original?.details?.gasFee;
                } else if (stepType === "blockchain") {
                    feeForMovement = feeForMovement || row?.original?.details?.totalGasFee;
                }
                return feeForMovement || "-"
            }
        },
    ];


    return !loading ? (
        <TransfiGrid
            columns={columns}
            dataUrl={`api/treasury/list/${orderId}`}
            filters={{}}
        ></TransfiGrid>
    ) : (
        <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={"Loading..."} color={"cyan.400"} size={100} />
    );
}